import {Component, computed, ElementRef, signal, ViewChild, WritableSignal} from '@angular/core';
import {LoadingMaskOptions} from 'frontend/src/app/classes/loading-mask-options';
import {NomChartConfigOptions} from 'frontend/src/shared/interfaces/nom-chart-config.interface';
import {BaseElement} from '../../../../classes/base-element';
import {ElementOptionsConfig} from '../../../../classes/element-option';

@Component({
    selector: 'eaglei-sit-rep-chart-element',
    templateUrl: './sit-rep-chart-element.component.html',
    styleUrls: ['../sit-rep-element.scss', './sit-rep-chart-element.component.scss'],
    standalone: false,
})
export class SitRepChartElementComponent extends BaseElement {
    @ViewChild('chartTarget') chartTarget: ElementRef<HTMLDivElement>;

    public filters = this.sitRepService.sitRepFilters;

    public chartStartDate = computed(() => {
        return this.filters().endDate.clone().subtract(12, 'hours');
    });

    public maskOptions: LoadingMaskOptions = new LoadingMaskOptions();
    public largeChart: WritableSignal<'large' | 'small'> = signal('large');
    public chartConfigOptions: NomChartConfigOptions = {
        type: 'bar',
    };
    public configureOptions: ElementOptionsConfig = {
        header: 'Chart Options',
        options: [
            {
                type: 'select',
                label: 'Chart Type',
                value: 'bar',
                selectOptions: [
                    {label: 'Bar', value: 'bar'},
                    {label: 'Line', value: 'line'},
                ],
            },
            {
                type: 'select',
                label: 'Y Axis Interval',
                value: 500000,
                selectOptions: [
                    {label: '10K', value: 10000},
                    {label: '50K', value: 50000},
                    {label: '100K', value: 100000},
                    {label: '500K', value: 500000},
                    {label: '1M', value: 1000000},
                    {label: '2M', value: 2000000},
                ],
            },
        ],
    };

    constructor() {
        super();
    }

    /**
     * Opens the Config Panel with the options of the element to change.
     * Waits for a response on what options to use.
     */
    protected configure(): void {
        this.sitRepService.openConfigSideBar(this as unknown as BaseElement, this.configureOptions);
    }

    public updateConfigValues(options: ElementOptionsConfig): void {
        // Handle updating the values from the config
        this.configureOptions = options;

        this.chartConfigOptions = {
            type: this.configureOptions.options.find((o) => o.label === 'Chart Type').value,
            yAxisInterval: this.configureOptions.options.find((o) => o.label === 'Y Axis Interval').value,
        };
    }
}
