import {AfterViewInit, Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {OutageComparison} from '../../classes/outage-comparison';
import {ApplicationConfig} from '../../../../classes/application-config';
import {throttleTime} from 'rxjs/operators';
import {ModalConfig} from '../../../../classes/modal-config';
import {StateNamePipe} from '../../../../../shared/pipes/state-name.pipe';

@Component({
    selector: 'eaglei-outage-comparison-modal',
    templateUrl: './outage-comparison-modal.component.html',
    styleUrls: ['../../../../../shared/modals/shared-modals.scss', './outage-comparison-modal.component.scss'],
    standalone: false,
})
export class OutageComparisonModalComponent implements AfterViewInit {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('content') content: ElementRef;

    public dataSource: MatTableDataSource<OutageComparison>;
    public columnNames = ['outageCount', 'overrideCount', 'outageDifference', 'runStartTime'];

    public readonly utilityName: string;
    public readonly countyName: string;
    private readonly statePipe = new StateNamePipe();

    constructor(public ref: MatDialogRef<OutageComparisonModalComponent>, @Inject(MAT_DIALOG_DATA) public data: OutageComparison[]) {
        this.utilityName = data[0].utilityName;
        this.countyName = `${data[0].countyName}, ${this.statePipe.transform(data[0].state, true)}`;
        this.initializeData(data);
    }

    ngAfterViewInit() {
        // Calling here to set up sort
        this.initializeData(this.dataSource.data);

        this.setModalHeight();
        ApplicationConfig.resizeEvent.pipe(throttleTime(250)).subscribe(() => this.setModalHeight());
    }

    private setModalHeight() {
        (this.content.nativeElement as HTMLElement).style.maxHeight = ModalConfig.getMaxModalHeight() + 'px';
    }

    private initializeData(data: OutageComparison[]): void {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.sortingDataAccessor = this.sortAccessor;
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    // noinspection JSMethodCanBeStatic
    private sortAccessor(data: OutageComparison, header: string): string | number {
        switch (header) {
            case 'outageCount':
                return data.outageCount;
            case 'overrideCount':
                return data.overrideCount;
            case 'outageDifference':
                return data.outageDifference;
            case 'runStartTime':
                return data.runStartTime.valueOf();
            default:
                return '';
        }
    }
}
