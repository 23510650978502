import {AfterViewInit, Component, inject, OnInit, ViewChild} from '@angular/core';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ModalConfig} from 'frontend/src/app/classes/modal-config';
import {HttpInterceptorService} from 'frontend/src/app/services/http-interceptor.service';
import moment from 'moment';
import {tap} from 'rxjs/operators';
import {Report} from '../../classes/report';
import {MaintenanceInfoModalComponent} from '../../modals/maintenance-info-modal/maintenance-info-modal.component';
import {ReportService} from '../../services/report.service';
import {FileDownload} from '../../../../classes/file-download';
import {OngService} from '../../../../services/ong.service';

@Component({
    selector: 'eaglei-maintenance-event-report',
    templateUrl: './maintenance-event-report.component.html',
    styleUrls: ['../reports.scss', './maintenance-event-report.component.scss'],
    standalone: false,
})
export class MaintenanceEventReportComponent extends Report implements OnInit, AfterViewInit {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    public readonly columnNames: string[] = ['name', 'subject', 'start', 'end', 'priority', 'quantity'];

    public selectedActiveDate: moment.Moment = moment();
    public selectedPriority: string = 'all';

    protected ongService = inject(OngService);
    public reportService = inject(ReportService);
    public dialog = inject(MatDialog);

    constructor() {
        super();
    }

    public ngOnInit(): void {
        this.reportService.getReportData().subscribe((res) => this.initializeReportInfo(res));
    }

    public ngAfterViewInit(): void {
        this.getMaintenance();
    }

    private getMaintenance(): void {
        HttpInterceptorService.clearInterceptor(this.uiHandle);
        HttpInterceptorService.pendingRequests[this.uiHandle] = this.reportService
            .getMaintenanceEvents(this.selectedActiveDate)
            .pipe(tap(() => HttpInterceptorService.deleteFromInterceptor(this.uiHandle)))
            .subscribe((res) => {
                this.initData(res);
            });
    }

    private initData(data: any[]): void {
        // noinspection DuplicatedCode
        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource(data);
            this.dataSource.filterPredicate = this.filterPredicate.bind(this);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sortingDataAccessor = this.dataAccessor.bind(this);
            this.dataSource.sort = this.sort;
        }

        this.dataSource.filter = ' ';
    }

    private filterPredicate(data: any): boolean {
        return this.selectedPriority !== 'all' ? data.priority === this.selectedPriority : true;
    }

    // noinspection JSMethodCanBeStatic
    private dataAccessor(data: any, header: string): string | number {
        switch (header) {
            case 'name':
                return data.name.toLowerCase();
            case 'subject':
                return data.subject;
            case 'start':
                return moment(data.start_date).valueOf();
            case 'end':
                return moment(data.end_date).valueOf();
            case 'priority':
                return data.priority;
            case 'quantity':
                return data.quantity_estimate_mmbtu;
            default:
                return '';
        }
    }

    public updatePriority(value: string): void {
        this.selectedPriority = value;
        this.dataSource.filter = ' ';
    }

    public updateDateRange(date: moment.Moment): void {
        this.selectedActiveDate = date.clone();

        this.getMaintenance();
    }

    public openMaintenanceModal(data: any): void {
        const config: MatDialogConfig = {
            width: ModalConfig.getModalWidth(),
            disableClose: true,
            autoFocus: false,
            data: {
                event: data,
            },
        };

        this.dialog.open(MaintenanceInfoModalComponent, config);
    }

    public exportTable(): void {
        let data: string = ['Pipeline Name', 'Subject', 'Start Date', 'End Date', 'Priority', 'Quantity (mtbu)'].join() + '\n';
        this.dataSource.filteredData.forEach((d) => {
            const values = [
                FileDownload.formatCsvCell(d.name),
                FileDownload.formatCsvCell(d.subject),
                FileDownload.formatCsvCell(Report.momentPipe.transform(d.start_date)),
                FileDownload.formatCsvCell(Report.momentPipe.transform(d.end_date)),
                FileDownload.formatCsvCell(d.priority),
                FileDownload.formatCsvCell(d.quantity_estimate_mmbtu),
            ];
            data += `${values.join()}\n`;
        });

        FileDownload.downloadCSV('ongMaintenanceEvents', data, this.attributionUrl);
    }
}
