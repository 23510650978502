import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Report} from '../../classes/report';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatSort} from '@angular/material/sort';
import {MatTable, MatTableDataSource} from '@angular/material/table';
import {StateCoverage} from '../../classes/state-coverage';
import {ReportService} from '../../services/report.service';
import moment from 'moment';
import {ColumnDef} from '../../../../../shared/classes/column-def';
import {CardFilters} from '../../../../../shared/classes/card-filters';
import {PopoverElement} from '../../../../classes/popover-element';
import {ApplicationConfig} from '../../../../classes/application-config';
import {ChartBounds} from '../../../../classes/chart-bounds';
import {ChartDataService} from '../../../../../shared/services/chart-data.service';
import {debounceTime} from 'rxjs/operators';
import {ModalConfig} from '../../../../classes/modal-config';
import {OutageChartDataModalComponent} from '../../../../../shared/modals/outage-chart-data-modal/outage-chart-data-modal.component';
import {FileDownload} from '../../../../classes/file-download';
import {EagleiBaseChart} from '../../../../classes/charts/base-chart';
import {DataPoint} from '../../../../classes/data-point';
import {State} from '../../../outage/classes/state';

@Component({
    selector: 'eaglei-state-coverage-report',
    templateUrl: './state-coverage-report.component.html',
    styleUrls: ['../reports.scss', './state-coverage-report.component.scss'],
    standalone: false,
})
export class StateCoverageReportComponent extends Report<StateCoverage> implements OnInit, AfterViewInit {
    // Properties from HTML
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild('materialTable') table: MatTable<StateCoverage>;
    @ViewChild('chartTarget') chartTarget: ElementRef<HTMLDivElement>;

    // Table Properties
    public columnNames: string[] = ['state', 'coveredCustomers', 'totalCustomers', 'coveragePercentage', 'trend'];
    public totalCovered = 0;
    public totalCustomers = 0;
    public totalCoverage = 0;

    // Modal Properties
    private historicalData: Map<string, StateCoverage[]> = new Map<string, StateCoverage[]>();
    private readonly modalColumnDefs = [
        // tslint:disable-next-line:max-line-length
        new ColumnDef('Coverage Date', (val: StateCoverage) => Report.momentPipe.transform(val.coverageDate))
            .setDefaultSort(true)
            .sort((val: StateCoverage) => val.coverageDate.valueOf())
            .setHasTooltip(true),
        new ColumnDef('Covered Customers', (val: StateCoverage) => Report.numberPipe.transform(val.coveredCustomersBestCase)).sort(
            (val: StateCoverage) => val.coveredCustomersBestCase
        ),
        new ColumnDef('Total Customers', (val: StateCoverage) => Report.numberPipe.transform(val.eiaTotalCustomers)).sort(
            (val: StateCoverage) => val.eiaTotalCustomers
        ),
        new ColumnDef('Coverage Percent', (val: StateCoverage) => Report.percentPipe.transform(val.percentage, '1.2-2')).sort(
            (val: StateCoverage) => val.percentage
        ),
    ];

    // Filter Properties
    public startDate: moment.Moment = moment().subtract(2, 'months').startOf('day');
    public endDate = moment();
    public readonly minDate = this.startDate.clone();
    public selectedStates: State[] = [];
    public dataToFilter: StateCoverage[] = [];
    // Chart Properties
    public baseChart = new EagleiBaseChart();
    public isLoading = true;

    constructor(public reportService: ReportService, private dialog: MatDialog, private chartService: ChartDataService) {
        super();
    }

    // Lifecycle Hooks
    ngOnInit() {
        this.getPreferences();
        this.reportService.getReportData().subscribe((r) => this.initializeReportInfo(r));
        this.getStateCoverage();
        this.isLoading = true;
    }

    ngAfterViewInit(): void {
        this.initializeChart();
    }

    // Data Methods

    /*** Gets the state coverage values for the selected date range.
     */
    private getStateCoverage(): void {
        this.reportService
            .getStateCoverage(this.startDate, this.endDate)
            .pipe(debounceTime(250))
            .subscribe((res) => {
                this.processStateData(res);
                this.dataToFilter = res;
            });
    }

    processStateData(stateData: StateCoverage[]) {
        this.historicalData = new Map<string, StateCoverage[]>();
        this.totalCoverage = 0;
        this.totalCovered = 0;
        this.totalCustomers = 0;
        const abbr = this.selectedStates.map((state) => state.abbreviation);

        stateData
            // this filter will remove any data that is not in the abbr array. the .includes() method ensures this by matching the elements
            .filter((coverage) => abbr.includes(coverage.state))
            .forEach((coverage) => {
                const val = this.historicalData.get(coverage.state) || [];

                if (val.length > 0) {
                    const first = val[0];
                    if (first.percentage > coverage.percentage) {
                        coverage.trend = -1;
                    } else if (first.percentage < coverage.percentage) {
                        coverage.trend = 1;
                    }
                }

                val.push(coverage);

                this.historicalData.set(coverage.state, val);
            });

        const tableStates = [];
        Array.from(this.historicalData.values()).forEach((coverageData) => {
            const coverage = coverageData[coverageData.length - 1];
            tableStates.push(coverage);
        });

        this.calculateTotals(tableStates);
        this.initializeData(tableStates);
    }

    /**
     * Checks all active data sums the total customers and total covered
     * @param data The current data in the table.
     */
    private calculateTotals(data: StateCoverage[]): void {
        this.totalCustomers = 0;
        this.totalCoverage = 0;
        this.totalCovered = 0;
        data.forEach((coverage) => {
            this.totalCovered += coverage.coveredCustomersBestCase || 0;
            this.totalCustomers += coverage.eiaTotalCustomers || 0;
        });

        this.totalCoverage = this.totalCustomers === 0 ? 0 : this.totalCovered / this.totalCustomers;
    }

    // Table Methods
    /**
     * Initializes the table data source and sets up sorting, if the source already exists it will just set the new table data.
     * @param data The data to be displayed in the table.
     */
    private initializeData(data: StateCoverage[]) {
        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource<StateCoverage>(data);
            this.dataSource.sortingDataAccessor = this.dataAccessor;
            this.dataSource.sort = this.sort;
        }
        this.renderChart();
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Initializes the logic for how the table is sorted.
     * @param data The StateCoverage Item being sorted
     * @param columnName The column that is being sorted by
     */
    private dataAccessor(data: StateCoverage, columnName: string): string | number {
        switch (columnName) {
            case 'state':
                return data.stateName;
            case 'coveredCustomers':
                return data.coveredCustomersBestCase;
            case 'totalCustomers':
                return data.eiaTotalCustomers;
            case 'coveragePercentage':
                return data.percentage;
            default:
                return '';
        }
    }

    private getPreferences() {
        const preferences = ApplicationConfig.currentUserPreferences.getValue();
        this.selectedStates = preferences.getStates();
    } // Chart Methods
    /**
     * Creates the needed canvases and charts with initial settings for displaying the data.
     */
    private initializeChart(): void {
        this.baseChart.initializeEChart(this.chartTarget.nativeElement, true, 'Date', 'Covered Customers');
    }

    /**
     * Returns the bounds and lines that should be displayed in the chart
     */
    private summarizeChartData(): ChartBounds<DataPoint> {
        const data = Array.from(this.historicalData.values())
            .reduce((prev, cur) => prev.concat(cur), [])
            .filter((coverage) => coverage.coverageDate.utc().isBetween(this.startDate, this.endDate, 'day', '[]'));

        const getKey = (c: StateCoverage) => c.coverageDate.valueOf();
        const createDp = (c: StateCoverage) => {
            const dp = new DataPoint();
            dp.data = {
                x: c.coverageDate.valueOf(),
                y: 0,
                total: 0,
                display: {
                    'Coverage Percent': 0,
                },
            };
            return dp;
        };

        const updateDp = (d: DataPoint, c: StateCoverage) => {
            d.data.y += c.coveredCustomersBestCase;
            d.data.total += c.eiaTotalCustomers;
            d.data.display['Coverage Percent'] = d.data.y / d.data.total;
        };

        const chartBounds = new ChartBounds<DataPoint>();
        chartBounds.rawData = data;
        chartBounds.data = this.chartService.getTimeBars(data, getKey, createDp, updateDp, 1000 * 60 * 60 * 24);

        return chartBounds;
    }

    /**
     * updates the axes and renders the chart with the new data
     */
    private renderChart(): void {
        const chartData = this.summarizeChartData();
        chartData.data.sort((a, b) => (a.data.x > b.data.x ? 1 : -1));

        const xValues = chartData.data.map((dp) => dp.data.x);

        const yValues = chartData.data.map((dp) => {
            const percent = dp.data.display ? dp.data.display['Coverage Percent'] : 0;
            return {
                value: dp.data.y,
                itemStyle: {color: ApplicationConfig.chartLineColor},
                popoverData: [
                    new PopoverElement().setTitle().setValue(Report.momentPipe.transform(dp.data.x)),
                    new PopoverElement()
                        .setLabel('Covered Customers')
                        .setValue(Report.numberPipe.transform(dp.data.y).toString())
                        .setSubValue('(Collected)')
                        .setSubValueClass('county-customer'),
                    new PopoverElement('Total Customers', `${Report.numberPipe.transform(dp.data.total)}`)
                        .setSubValue('(Collected)')
                        .setSubValueClass('county-customer'),
                    new PopoverElement('Coverage Percent', `${Report.percentPipe.transform(percent, '1.2-2')}`),
                ],
                exportData: {
                    yValue: dp.data.y,
                    xValue: moment(dp.data.x).format(),
                    percent: Report.percentPipe.transform(percent, '1.2-2'),
                    total: Report.numberPipe.transform(dp.data.total),
                },
            };
        });

        this.baseChart.eChartOptions.xAxis['data'] = xValues;

        this.baseChart.eChartOptions.series = [
            {
                type: 'bar',
                data: yValues,
            },
        ];

        this.baseChart.eChart.setOption(this.baseChart.eChartOptions);
    }

    /**
     * Returns the text that should be displayed in the chart mask.
     */
    public getMaskText(): string {
        let text = 'Loading...';
        if (this.dataSource && this.dataSource.data.length === 0) {
            text = 'No data for selected filters';
        }
        return text;
    }

    // Filter Methods
    /**
     * Updates the selected states
     * @param states States to be changed
     */
    public updateStateFilter(states: State[]): void {
        this.selectedStates = states;
        this.processStateData(this.dataToFilter); // gets data from getStateCoverage()
    }

    /**
     * Called when the dates from the date picker update and updates the chart and table to match the new dates
     * @param dates The new date boundaries for the chart.
     */
    public updateDates(dates: CardFilters): void {
        this.startDate = dates.startDate;
        this.endDate = dates.endDate;
        this.getStateCoverage();
    }

    /**
     * Updates the chips for the selected states
     * @param states States selected
     */
    public changeSelectedChips(states: State[]): void {
        this.selectedStates = states.slice();
        this.getStateCoverage();
    }

    // Modal Methods
    /**
     * Opens the state history modal.
     * @param coverage The state history that is being checked
     */
    public viewStateHistory(coverage: StateCoverage) {
        const data = this.historicalData
            .get(coverage.state)
            .filter((c) => c.coverageDate.isBetween(this.startDate, this.endDate, 'day', '[]'));
        const opts: MatDialogConfig = {
            data: {
                tableData: data,
                columnDefs: this.modalColumnDefs,
                title: `Coverage data for ${coverage.stateName}`,
            },
            width: ModalConfig.getModalWidth(),
        };

        this.dialog.open(OutageChartDataModalComponent, opts);
    }

    public getCountyCustomerText(info: any): string {
        return !!info.eiaTotalCustomers ? `(${info.modelCount > 0 ? 'Modeled' : 'Collected'})` : '';
    }

    // Export Methods
    /**
     * Exports the table as a CSV file
     */
    public exportTable() {
        let data: string = ['State/Territory', 'Covered Customers', 'Total Customers', 'Coverage Percent', 'Trend'].join() + '\n';
        this.dataSource._orderData(this.dataSource.filteredData).forEach((state) => {
            let trend = 'Flat';
            if (state.trend === 1) {
                trend = 'up';
            } else if (state.trend === -1) {
                trend = 'down';
            }

            const info = [
                FileDownload.formatCsvCell(state.stateName),
                FileDownload.formatCsvCell(Report.numberPipe.transform(state.coveredCustomersBestCase)),
                FileDownload.formatCsvCell(Report.numberPipe.transform(state.eiaTotalCustomers)),
                FileDownload.formatCsvCell(Report.percentPipe.transform(state.percentage, '1.2-2')),
                trend,
            ];
            data += `${info.join()}\n`;
        });

        data += [
            'Total',
            FileDownload.formatCsvCell(Report.numberPipe.transform(this.totalCovered)),
            FileDownload.formatCsvCell(Report.numberPipe.transform(this.totalCustomers)),
            FileDownload.formatCsvCell(Report.percentPipe.transform(this.totalCoverage, '1.2-2')),
        ].join();

        FileDownload.downloadCSV('stateCoverage', data, this.attributionUrl);
    }

    /**
     * Exports the chart as a CSV file
     */
    public exportChartAsCsv(): void {
        let data = '';

        const columns = ['Date', 'Covered Customers', 'Total Customers', 'Coverage Percent'].join() + '\n';

        data += columns;

        this.baseChart.eChart.getOption().series.forEach((series) => {
            series.data.forEach((sd) => {
                data +=
                    [
                        FileDownload.formatCsvCell(sd.exportData.xValue),
                        FileDownload.formatCsvCell(sd.exportData.yValue),
                        FileDownload.formatCsvCell(sd.exportData.total),
                        FileDownload.formatCsvCell(sd.exportData.percent),
                    ].join() + '\n';
            });
        });

        FileDownload.downloadCSV('StateCoverageTrend', data, this.attributionUrl);
    }

    /**
     * Exports the chart as a PNG image
     */
    public exportChart(): void {
        const title =
            `EAGLE-I State Coverage for ` +
            `${Report.momentPipe.transform(this.startDate, 'M/DD/YYYY')} to ` +
            `${Report.momentPipe.transform(this.endDate, 'M/DD/YYYY')}`;

        FileDownload.exportChartAsPNG(this.baseChart, 'outageChart', title, this.attributionUrl);
    }
}
