import {Component, inject, OnInit, ViewChild} from '@angular/core';
import {Report} from '../../classes/report';
import {ReportService} from '../../services/report.service';
import {LoadingMaskOptions} from '../../../../classes/loading-mask-options';
import {MatSort} from '@angular/material/sort';
import {MatPaginator} from '@angular/material/paginator';
import {MatTableDataSource} from '@angular/material/table';
import {HeadoutSource} from '../../../layer/sources/headout-source';
import {LayerService} from '../../../layer/services/layer.service';
import {LayerStyleService} from '../../../layer/services/layer-style.service';
import {HeadoutTableData} from '../../../../integrations/headout/interfaces/headout-table-data.interface';
import {headoutTableColumnNames, HeadoutTableSort} from '../../../../integrations/headout/functions/table-utils';
import {exportHeadoutAsCsv} from '../../../../integrations/headout/functions/export-headout-csv';
import {getFontColor} from '../../../../integrations/headout/functions/font-color';
import {HeadoutMapControls} from '../../../../integrations/headout/classes/headout-base';
import {forkJoin} from 'rxjs/internal/observable/forkJoin';
import {HeadoutService} from '../../../../integrations/headout/headout.service';
import {SystemEventService} from '../../../system-event/services/system-event.service';
import {SystemEventType} from '../../../system-event/classes/system-event-type';

@Component({
    selector: 'eaglei-headout-report',
    templateUrl: './headout-report.component.html',
    styleUrls: ['../reports.scss', './headout-report.component.scss'],
    standalone: false,
})
export class HeadoutReportComponent extends Report<HeadoutTableData> implements OnInit {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    private headoutService = inject(HeadoutService);
    public systemEventService = inject(SystemEventService);
    public styleService = inject(LayerStyleService);

    public eventTypes: SystemEventType[] = [];

    // Table Properties
    public columns: string[] = headoutTableColumnNames;

    public maskOptions = new LoadingMaskOptions();

    public mapControls = new HeadoutMapControls(true);

    constructor(public reportService: ReportService, private layerService: LayerService) {
        super();
    }

    ngOnInit(): void {
        this.reportService.getReportData().subscribe((r) => this.initializeReportInfo(r));

        forkJoin([
            this.headoutService.getMostRecentEvent(),
            this.layerService.getLayerByHandle('headoutHurricaneInformation'),
            this.systemEventService.getEventTypes(),
        ]).subscribe((res) => {
            this.eventTypes = res[2];
            this.mapControls.mapLayer = res[1];
            this.mapControls.layerSource = new HeadoutSource(res[1], this.styleService);
            this.mapControls.setInitialEvent(res[0]);
        });

        this.mapControls.updateTableData.subscribe((data) => {
            this.initializeData(data);
        });
    }

    // Table Methods
    private initializeData(data: HeadoutTableData[]): void {
        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource<HeadoutTableData>(data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sortingDataAccessor = HeadoutTableSort;
            this.dataSource.sort = this.sort;
        }
    }

    // Map Method

    /**
     * Exports the data table as a CSV file
     */
    public exportTable() {
        exportHeadoutAsCsv(this.dataSource._orderData(this.dataSource.filteredData), 'HEADOUT_event', this.attributionUrl);
    }

    public getFontColor(color: string): string {
        return getFontColor(color);
    }
}
