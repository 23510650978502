import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Report} from '../../classes/report';
import {ReportService} from '../../services/report.service';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {OutageComparison} from '../../classes/outage-comparison';
import moment from 'moment';
import {CardFilters} from '../../../../../shared/classes/card-filters';
import {DataService} from '../../../../services/data.service';
import {ChartDataService} from '../../../../../shared/services/chart-data.service';
import {FileDownload} from '../../../../classes/file-download';
import {OutageComparisonModalComponent} from '../../modals/outage-comparison-modal/outage-comparison-modal.component';
import {ApplicationConfig} from '../../../../classes/application-config';
import {State} from '../../../outage/classes/state';
import {PopoverElement} from '../../../../classes/popover-element';
import {HttpInterceptorService} from '../../../../services/http-interceptor.service';
import {EagleiBaseChart} from '../../../../classes/charts/base-chart';

interface ChartDataset {
    date: moment.Moment;
    outage: number;
    override: number;
}

@Component({
    selector: 'eaglei-outage-comparison-report',
    templateUrl: './outage-comparison-report.component.html',
    styleUrls: ['../reports.scss', './outage-comparison-report.component.scss'],
    standalone: false,
})
export class OutageComparisonReportComponent extends Report<OutageComparison> implements OnInit, AfterViewInit {
    // HTML Properties
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('chartTarget') chartTarget: ElementRef;
    @ViewChild('chartLegend') chartLegend: ElementRef;

    // Table Properties
    public columnNames: string[] = ['utilityName', 'state', 'county', 'outageCount', 'overrideCount', 'runStartTime'];

    // Chart Properties
    public baseChart = new EagleiBaseChart();
    public overrideLineColor = ApplicationConfig.overrideColor;
    public outageLineColor = ApplicationConfig.chartLineColor;
    public mask = {
        show: true,
        isLoading: false,
        initial: true,
        chartHasData: false,
    };

    // Filter Properties
    public selectedStates = DataService.states.getValue().slice();
    public startDate = moment().subtract(1, 'days').startOf('day');
    public endDate = moment();

    public outages: Map<string, OutageComparison[]>;

    private timeoutHandle: any;

    constructor(public reportService: ReportService, private chartService: ChartDataService, private dialog: MatDialog) {
        super();
    }

    ngOnInit() {
        this.reportService.getReportData().subscribe((res) => this.initializeReportInfo(res));
    }

    ngAfterViewInit(): void {
        const preferences = ApplicationConfig.currentUserPreferences.getValue();
        if (preferences.hasLegendStyle()) {
            this.overrideLineColor = preferences.legendStyle.overrideColor;
        }
        this.initializeChart();
        this.getComparisons();
    }

    /**
     * Makes an API call to get all comparison data between a start and end date.
     */
    private getComparisons(): void {
        this.mask.show = true;
        HttpInterceptorService.clearInterceptor(this.uiHandle);
        HttpInterceptorService.pendingRequests[this.uiHandle] = this.reportService
            .getOutageComparisons(this.startDate, this.endDate)
            .subscribe((comparisons) => {
                HttpInterceptorService.deleteFromInterceptor(this.uiHandle);
                this.processComparisons(comparisons);
            });
    }

    /**
     * Transforms the list of comparisons receivedd from API to a list of unique utilities
     * @param data The Outage Comparisons received from the API
     */
    private processComparisons(data: OutageComparison[]): void {
        this.outages = new Map<string, OutageComparison[]>();

        data.forEach((comparison) => {
            const val = this.outages.get(comparison.id) || [];
            val.push(comparison);
            val.sort((a, b) => (a.runStartTime.isSameOrBefore(b.runStartTime) ? 1 : -1));
            this.outages.set(comparison.id, val);
        });

        // This should get the most recent, not the first
        const columns = Array.from(this.outages.values()).map((comparisons) => comparisons[0]);
        this.initializeData(columns);
    }

    // Table functions
    private initializeData(data: OutageComparison[]): void {
        if (!this.dataSource) {
            this.dataSource = new MatTableDataSource(data);
            this.dataSource.filterPredicate = this.filterPredicate.bind(this);
            this.dataSource.sortingDataAccessor = this.sortAccessor;
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.renderChartData();
        } else {
            this.dataSource.data = data;
            this.filterUtilities(this.dataSource.filter);
        }
    }

    private filterPredicate(data: OutageComparison, text: string): boolean {
        const nameCheck = data.utilityName.trim().toLowerCase().includes(text.trim().toLowerCase());
        const stateCheck = this.selectedStates.map((state) => state.id).indexOf(data.stateId) !== -1;
        return nameCheck && stateCheck;
    }

    // noinspection JSMethodCanBeStatic
    private sortAccessor(data: OutageComparison, header: string): string | number {
        switch (header) {
            case 'utilityName':
                return data.utilityName.toLowerCase().trim();
            case 'state':
                return data.state;
            case 'county':
                return data.countyName.toLowerCase().trim();
            case 'outageCount':
                return data.outageCount;
            case 'overrideCount':
                return data.overrideCount;
            case 'runStartTime':
                return data.runStartTime.valueOf();
            default:
                return '';
        }
    }

    // Filter Functions
    /**
     * Updates the date range of outage comparison in the table
     * @param dates The new start and end date being used
     */
    public changeDateRange(dates: CardFilters): void {
        this.startDate = dates.startDate;
        this.endDate = dates.endDate;

        this.getComparisons();
    }

    /**
     * Filters the utilities in the table based of input text and selected locations
     * @param text The text being searched for in the utility name
     */
    public filterUtilities(text: string): void {
        text = text === '' ? ' ' : text;
        this.dataSource.filter = text;

        this.mask.show = true;
        if (this.timeoutHandle) {
            clearTimeout(this.timeoutHandle);
        }
        this.timeoutHandle = setTimeout(() => {
            this.renderChartData();
        }, 500);
    }

    // Chart Functions
    /**
     * Sets up the initial values for the charts x and y axis as well as the data zoom feature.
     */
    private initializeChart(): void {
        this.baseChart.initializeEChart(this.chartTarget.nativeElement, true, 'Date', 'Customers Without Power');

        const additionalProperties = {
            dataZoom: {
                start: 90,
                end: 100,
                left: 100,
                right: 100,
                handleIcon:
                    'M10.7,11.9v-1.3H9.3v1.3c-4.9,0.3-8.8,4.4-8.8,9.4c0,5,3.9,9.1,8.8,9.4v1.3h1.3v-1.3c4.9-0.3,8.8-4.4,8.8-9.4C19.5,16.3,15.6' +
                    ',12.2,10.7,11.9z M13.3,24.4H6.7V23h6.6V24.4z M13.3,19.6H6.7v-1.4h6.6V19.6z',
                handleSize: '80%',
                handleStyle: {
                    color: '#fff',
                    shadowBlur: 3,
                    shadowColor: 'rgba(0, 0, 0, 0.6)',
                    shadowOffsetX: 2,
                    shadowOffsetY: 2,
                },
                labelFormatter: (value, valueStr) => {
                    const val = parseInt(valueStr);
                    return moment(val).format('M/DD h:mm A');
                },
            } as any,
            dataset: {
                dimensions: ['date', 'outage', 'override'],
            },
            series: [
                {
                    type: 'bar',
                    name: 'Outage',
                    barGap: '0',
                    itemStyle: {
                        color: ApplicationConfig.chartLineColor,
                    },
                },
                {
                    type: 'bar',
                    name: 'Override',
                    itemStyle: {
                        color: this.overrideLineColor,
                    },
                },
            ],
        };

        this.baseChart.eChartOptions = {
            ...this.baseChart.eChartOptions,
            ...additionalProperties,
        };

        (this.baseChart.eChartOptions.grid as any).bottom = 80;
    }

    /**
     * Summarizes the table data by timestep
     */
    private getSummarizedChartLines(): Map<number, ChartDataset> {
        const filtered: OutageComparison[] = this.dataSource.filteredData.reduce((prev, cur) => prev.concat(this.outages.get(cur.id)), []);

        const dataMap = new Map<number, ChartDataset>();

        if (filtered.length === 0) {
            return dataMap;
        }

        filtered.forEach((comparison) => {
            const key = comparison.runStartTime.valueOf();
            let val = dataMap.get(key);

            if (!val) {
                val = {
                    date: comparison.runStartTime,
                    outage: 0,
                    override: 0,
                };
            }

            val.override += comparison.overrideCount;
            val.outage += comparison.outageCount;

            dataMap.set(key, val);
        });

        // Add in missing data
        // Subtracting 15 minutes so it does not include a the most recent run time that has not finished
        for (let i = this.startDate.valueOf(); i < this.endDate.subtract(15, 'minutes').valueOf(); i += 1000 * 60 * 15) {
            if (!dataMap.has(i)) {
                const val = {
                    date: moment(i),
                    outage: 0,
                    override: 0,
                };
                dataMap.set(i, val);
            }
        }
        return dataMap;
    }

    /**
     * Filters the outage comparisons based off of filters and then aggregates and sorts the data before rendering the chart.
     */
    private renderChartData(): void {
        const chartData = this.getSummarizedChartLines();

        // noinspection UnnecessaryLocalVariableJS
        const values = Array.from(chartData.values()).sort((a, b) => {
            if (a.date.isBefore(b.date)) {
                return -1;
            } else if (a.date.isAfter(b.date)) {
                return 1;
            }
            return 0;
        });

        const chartSource = values.map((val) => {
            return {
                date: val.date.valueOf(),
                outage: val.outage,
                override: val.override,
                popoverData: [
                    new PopoverElement('', Report.momentPipe.transform(val.date)).setTitle(),
                    new PopoverElement('Outages Reported', Report.numberPipe.transform(val.outage).toString()),
                    new PopoverElement('Overrides Active', Report.numberPipe.transform(val.override).toString()),
                ],
            };
        });

        this.baseChart.eChartOptions.dataset['source'] = chartSource;

        this.baseChart.eChart.setOption(this.baseChart.eChartOptions);

        this.mask.initial = false;
        this.mask.chartHasData = chartSource.length !== 0;
        this.mask.show = chartSource.length === 0;
    }

    // Modal Options
    /**
     * Fired on row click, this will open a modal showing the comparisons over time for a utility.
     * @param data The utility being expanded.
     */
    public openComparisonModal(data: OutageComparison): void {
        const opts: MatDialogConfig = {
            data: this.outages.get(data.id),
            autoFocus: false,
        };
        this.dialog.open(OutageComparisonModalComponent, opts);
    }

    // Export Functions
    /**
     * Exports the data in the table as a CSV
     */
    public exportTable(): void {
        // let exportData = ['Utility Name', 'State', 'County'].join() + '\n';
        let exportData = '';

        this.dataSource._orderData(this.dataSource.filteredData).forEach((val) => {
            exportData +=
                [
                    FileDownload.formatCsvCell(val.utilityName),
                    FileDownload.formatCsvCell(val.state),
                    FileDownload.formatCsvCell(val.countyName),
                ].join() + '\n';

            exportData += ['', 'Outage Count', 'Override Count', 'Override Difference', 'Run Start Time'].join() + '\n';

            this.outages
                .get(val.id)
                .sort((a, b) => {
                    return a.runStartTime > b.runStartTime ? 1 : -1;
                })
                .forEach((outage) => {
                    exportData +=
                        [
                            '',
                            FileDownload.formatCsvCell(outage.outageCount),
                            FileDownload.formatCsvCell(outage.overrideCount),
                            FileDownload.formatCsvCell(outage.outageDifference),
                            FileDownload.formatCsvCell(Report.momentPipe.transform(outage.runStartTime)),
                        ].join() + '\n';
                });

            exportData += '\n\n';
        });

        FileDownload.downloadCSV('outageComparison', exportData, this.attributionUrl);
    }

    /**
     * Exports the chart as a PNG
     */
    public exportChart(): void {
        const title =
            `Outage Comparison for ` +
            `${Report.momentPipe.transform(this.startDate, 'M/DD/YYYY')} to ` +
            `${Report.momentPipe.transform(this.endDate, 'M/DD/YYYY')}`;
        FileDownload.exportChartAsPNG(this.baseChart, 'outageComparison', title, this.attributionUrl, '', this.chartLegend.nativeElement);
    }

    /**
     * Exports the chart as a CSV
     */
    public exportChartAsCsv(): void {
        let outageLine = 'EAGLE-I Outage Data\n';
        outageLine += 'Date, Customers Out\n';

        let overrideLine = 'EOC Data Override\n';
        overrideLine += 'Date, Customers Out\n';

        (this.baseChart.eChartOptions.dataset['source'] as any[]).forEach((entry) => {
            outageLine += `${Report.momentPipe.transform(entry.date)},${FileDownload.formatCsvCell(
                Report.numberPipe.transform(entry.outage)
            )}\n`;
            overrideLine += `${Report.momentPipe.transform(entry.date)},${FileDownload.formatCsvCell(
                Report.numberPipe.transform(entry.override)
            )}\n`;
        });

        const data = `${outageLine} \n\n ${overrideLine}`;

        FileDownload.downloadCSV('outageComparisonTrend', data, this.attributionUrl);
    }

    // Chart Mask Functions
    /**
     * Returns the text in the chart mask based on what data isavailablee for the chart.
     */
    public getMaskText(): string {
        let text = 'Loading...';
        if (!this.mask.initial) {
            if (!this.mask.chartHasData) {
                text = 'No Data Matching Filters';
            }
            if (this.selectedStates.length === 0) {
                text = 'No Filters Selected';
            }
        }

        return text;
    }

    /**
     * Checks to see if there is any input in the search utilities text box
     */
    public isSearchTextActive(): boolean {
        return this.dataSource && this.dataSource.filter.trim().length > 0;
    }

    /**
     * Fired when the filter location select or the filter chip list is updated. This will set the list of filtered
     * states and then filter the table and chart.
     * @param states The states that will be filted down to.
     */
    public changeSelectedLocations(states: State[]): void {
        this.selectedStates = states.slice();
        this.filterUtilities(this.dataSource.filter);
    }
}
