import {AfterViewInit, Component, OnDestroy, OnInit, ViewChild, inject} from '@angular/core';
import {FormControl} from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {GenSystemEventAnalytic} from 'frontend/generated/serverModels/GenSystemEventAnalytic';
import {FileDownload} from 'frontend/src/app/classes/file-download';
import {GroupedHeadoutEvent} from 'frontend/src/app/integrations/headout/interfaces/grouped-headout-event';
import {WaoService} from 'frontend/src/app/services/wao.service';
import moment from 'moment';
import {filter, takeUntil} from 'rxjs/operators';
import {LeafletMapLayer} from '../../../layer/classes/leaflet-map-layer';
import {LayerService} from '../../../layer/services/layer.service';
import {WAOLightsSource} from '../../../layer/sources/wao-lights-source';
import {MapOptions} from '../../../map/classes/map-options';
import {SystemEventService} from '../../../system-event/services/system-event.service';
import {Report} from '../../classes/report';
import {WaoStructure} from '../../classes/wao-structure';
import {ReportService} from '../../services/report.service';

@Component({
    selector: 'eaglei-wao-report',
    templateUrl: './wao-report.component.html',
    styleUrls: ['../reports.scss', './wao-report.component.scss'],
    standalone: false,
})
export class WaoReportComponent extends Report<WaoStructure> implements OnInit, AfterViewInit, OnDestroy {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    // Services
    public layerService = inject(LayerService);
    public reportService = inject(ReportService);
    public waoService = inject(WaoService);
    public systemEventService = inject(SystemEventService);

    // Table Properties
    public readonly columnNames: string[] = ['state', 'county', 'structure', 'light'];

    // Filters
    public waoEvents: GroupedHeadoutEvent = {active: [], inactive: []};
    public dateList: moment.Moment[] = [];
    public dateControl: FormControl = new FormControl('');
    public eventControl: FormControl = new FormControl('');
    public lightControl: FormControl<number[]> = new FormControl<number[]>([-1, 1, 3]);

    // Map Properties
    public mapOptions: MapOptions;
    private mapLayer: LeafletMapLayer;
    public layerSource: WAOLightsSource;

    constructor() {
        super();

        this.mapOptions = new MapOptions().setZoom(5, 1).setCenter(37.2, -92);
        this.mapOptions.onlyManualZoom = true;
        this.mapOptions.show = {
            sidebar: false,
            refresh: false,
            export: false,
            coverage: false,
            coordinate: false,
            zoom: true,
            panControl: true,
        };
    }

    public ngOnInit(): void {
        this.reportService.getReportData().subscribe((r) => this.initializeReportInfo(r));

        this.layerService.getLayerByHandle('waoLights').subscribe((layer) => {
            this.mapLayer = layer;
            this.layerSource = new WAOLightsSource(layer);

            this.systemEventService.getGroupedEvents().subscribe(() => {
                this.waoEvents.active = this.systemEventService.groupedEvents$.value?.active.filter((e) =>
                    e.analytics.includes(GenSystemEventAnalytic.WAO)
                );
                this.waoEvents.inactive = this.systemEventService.groupedEvents$.value?.inactive.filter((e) =>
                    e.analytics.includes(GenSystemEventAnalytic.WAO)
                );

                if (this.waoEvents.active.length > 0) {
                    this.eventControl.setValue(this.waoEvents.active[0]);
                } else if (this.waoEvents.inactive.length > 0) {
                    this.eventControl.setValue(this.waoEvents.inactive[0]);
                }
            });
        });
    }

    public ngAfterViewInit(): void {
        this.dateControl.valueChanges
            .pipe(
                takeUntil(this.destroy$),
                filter((val) => !!val)
            )
            .subscribe((val: moment.Moment) => this.updateDate());

        this.eventControl.valueChanges
            .pipe(
                takeUntil(this.destroy$),
                filter((val) => !!val)
            )
            .subscribe((val) => {
                this.dateList = [];

                const event = this.eventControl.value;
                this.waoService.getWAOFilterDates(event.eventStart, event.eventEnd).subscribe((res: moment.Moment[]) => {
                    this.dateList.push(...res.map((d) => moment(d)));
                    this.dateControl.setValue(moment(this.dateList[this.dateList.length - 1]));
                });
            });

        this.lightControl.valueChanges
            .pipe(
                takeUntil(this.destroy$),
                filter((val) => !!val)
            )
            .subscribe((val) => {
                this.updateDate();
                this.dataSource.filter = ' ';
            });
    }

    ngOnDestroy() {
        this.destroy$.next(undefined);
        this.destroy$.unsubscribe();
    }

    private getData(): void {
        this.waoService.getWAOStructures(moment(this.dateControl.value).utc()).subscribe((res) => {
            this.initData(res);
        });
    }

    private initData(data: WaoStructure[]): void {
        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource<WaoStructure>(data);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sortingDataAccessor = this.dataAccessor.bind(this);
            this.dataSource.sort = this.sort;
            this.dataSource.filterPredicate = this.filterPredicate.bind(this);
        }

        this.dataSource.filter = ' ';
    }

    private filterPredicate(data: WaoStructure): boolean {
        return this.lightControl.value.includes(data.lightStatus);
    }

    private dataAccessor(data: WaoStructure, header: string): string | number {
        switch (header) {
            case 'state':
                return data.state?.name?.toLowerCase();
            case 'county':
                return data.countyName.toLowerCase();
            case 'structure':
                return data.structureCount;
            case 'light':
                return data.lightStatus;
            default:
                return '';
        }
    }

    /**
     * Update table and map with selected date and light status
     */
    public updateDate(): void {
        this.layerSource.selectedDate = moment(this.dateControl.value);
        this.layerSource.selectedLightStatus = this.lightControl.value;
        this.layerSource.removeFromMap();
        if (this.dateList.length > 0) {
            this.layerSource.fetchTiles();
        }

        this.getData();
    }

    /**
     * Returns the Label of the light status
     * @param status Light Status number
     * @returns Label of Light status
     */
    public getLightStatus(status: number): string {
        switch (status) {
            case -1:
                return 'No Lights';
            case 1:
                return 'Increased Light';
            case 3:
                return 'Clouds';
            default:
                return '';
        }
    }

    /**
     * Compares two dates to see if they are the same date
     * @param date1 First Date
     * @param date2 Second Date
     * @returns if the given dates are the same
     */
    public dateCompare(date1: moment.Moment, date2: moment.Moment): boolean {
        return date1.isSame(date2);
    }

    /**
     * Exports the data from the table as a CSV
     */
    public exportTable(): void {
        let data = '';
        const headerColumns = ['State', 'County', 'Structure Count', 'Light'];

        data += headerColumns.join();
        data += '\n';

        this.dataSource._orderData(this.dataSource.filteredData).forEach((d) => {
            const values = [
                FileDownload.formatCsvCell(d.state.name),
                FileDownload.formatCsvCell(d.countyName),
                FileDownload.formatCsvCell(d.structureCount),
                FileDownload.formatCsvCell(this.getLightStatus(d.lightStatus)),
            ];

            data += values.join();
            data += '\n';
        });

        FileDownload.downloadCSV('wao-lights', data, this.mapLayer.attributionUrl);
    }
}
