import {Component, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {UtilityError} from '../../classes/utility-error';
import {BaseModal} from '../../../../classes/base-modal';

@Component({
    selector: 'eaglei-utility-error-modal',
    templateUrl: './utility-error-modal.component.html',
    styleUrls: ['../../../../../shared/modals/shared-modals.scss', './utility-error-modal.component.scss'],
    standalone: false,
})
export class UtilityErrorModalComponent extends BaseModal {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    private readonly utilityErrors: UtilityError[];
    public utilityName: string;
    public ds: MatTableDataSource<UtilityError> = new MatTableDataSource<UtilityError>();
    public columnNames = ['timestamp', 'parent_error_type', 'specific_error_type', 'error_text'];

    constructor(public dialogRef: MatDialogRef<UtilityErrorModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        super();
        this.utilityName = data.name;
        this.utilityErrors = data.errors;
    }

    afterInit() {
        this.initializeTable(this.utilityErrors);
    }

    private initializeTable(data: UtilityError[]): void {
        this.ds.data = data;
        this.ds.sortingDataAccessor = this.dataAccessor;
        this.ds.sort = this.sort;
        this.ds.paginator = this.paginator;
    }

    // noinspection JSMethodCanBeStatic
    private dataAccessor(data: UtilityError, column: string): string | number {
        switch (column) {
            case 'error_type_id':
                return data.errorTypeId;
            case 'parent_error_type':
                return data.parentErrorType;
            case 'specific_error_type':
                return data.specificErrorType;
            case 'error_text':
                return data.errorText;
            case 'timestamp':
                return data.timestamp.valueOf();
            default:
                return '';
        }
    }
}
