import {Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Report} from '../../classes/report';
import {ReportService} from '../../services/report.service';
import {ApiKey} from '../../../api-key/classes/api-key';
import {MatButtonToggleChange} from '@angular/material/button-toggle';
import {FileDownload} from 'frontend/src/app/classes/file-download';

type Status = 'all' | 'active' | 'expired';

@Component({
    selector: 'eaglei-api-key-report',
    templateUrl: './api-key-report.component.html',
    styleUrls: ['../reports.scss', './api-key-report.component.scss'],
    standalone: false,
})
export class ApiKeyReportComponent extends Report implements OnInit {
    // HTML Elements
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    // Table Properties
    public readonly columnNames = ['user', 'name', 'email', 'status', 'key', 'system', 'data', 'ogc', 'expiration'];
    public dataSource: MatTableDataSource<ApiKey>;

    // Filter Properties
    public readonly keyStatuses: Status[] = ['all', 'active', 'expired'];
    public activeStatus: Status = 'all';

    constructor(public reportService: ReportService) {
        super();

        this.reportService.getApiKeys().subscribe((keys) => {
            this.initializeDataSource(keys);
        });
    }

    ngOnInit() {
        this.reportService.getReportData().subscribe((r) => this.initializeReportInfo(r));
    }

    // Table Methods
    private initializeDataSource(data: ApiKey[]) {
        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource<ApiKey>(data);
            this.dataSource.sortingDataAccessor = this.dataAccessor;
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.dataSource.filterPredicate = this.filterPredicate.bind(this);
        }
    }

    private filterPredicate(data: ApiKey, text: string): boolean {
        const fields = [data.fullName, data.key, data.username, data.email];
        const fieldCheck = fields.some((field) => field.toLowerCase().includes(text.toLowerCase()));

        if (this.activeStatus === 'active') {
            return !data.expired && fieldCheck;
        } else if (this.activeStatus === 'expired') {
            return data.expired && fieldCheck;
        }
        return fieldCheck;
    }

    // noinspection JSMethodCanBeStatic
    private dataAccessor(data: ApiKey, columnName: string): string | number {
        switch (columnName) {
            case 'key':
                return data.key;
            case 'name':
                return data.fullName.toLowerCase();
            case 'email':
                return data.email.toLowerCase();
            case 'user':
                return data.username.toLowerCase();
            case 'data':
                return data.useCount;
            case 'ogc':
                return data.ogcCount;
            case 'expiration':
                return data.expirationDateInMs;
            case 'system':
                return data.system.toLowerCase() || '';
            default:
                return '';
        }
    }

    // Export Methods
    public exportTable() {
        let data: string =
            [
                'Username',
                'Name',
                'Email',
                'Status',
                'API-Key',
                'System Supported',
                'Times Used For Data Access',
                'Times Used For OGC Services',
                'Expiration Date',
            ].join() + '\n';

        const sortedKeys = this.dataSource._orderData(this.dataSource.filteredData);

        sortedKeys.forEach((key) => {
            const status: string = key.expired ? 'Expired' : 'Active';
            const info = [
                FileDownload.formatCsvCell(key.username),
                FileDownload.formatCsvCell(key.fullName),
                FileDownload.formatCsvCell(key.email),
                FileDownload.formatCsvCell(status),
                FileDownload.formatCsvCell(key.key),
                FileDownload.formatCsvCell(key.system),
                FileDownload.formatCsvCell(key.useCount),
                FileDownload.formatCsvCell(key.ogcCount),
                FileDownload.formatCsvCell(Report.momentPipe.transform(key.expirationDate)),
            ];
            data += `${info.join()}\n`;
        });

        FileDownload.downloadCSV('apiKeys', data, this.attributionUrl);
    }

    // Filter Methods
    public filterKeys(value: string) {
        value = value === '' ? ' ' : value;
        this.dataSource.filter = value;

        if (this.dataSource.paginator) {
            this.dataSource.paginator.firstPage();
        }
    }

    public filterStatus(event: MatButtonToggleChange): void {
        this.activeStatus = event.value;
        this.filterKeys(this.dataSource.filter);
    }

    public isSearchTextActive(): boolean {
        return this.dataSource && this.dataSource.filter.trim().length > 0;
    }
}
