import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSelectChange} from '@angular/material/select';
import {MatSnackBar} from '@angular/material/snack-bar';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {User} from '../../../user/classes/user';
import {GenRoleDefinition} from '../../../../../../generated/serverModels/GenRoleDefinition';
import {ActivatedRoute, Router} from '@angular/router';
import {UserService} from '../../../user/services/user.service';
import {AuthenticationService} from '../../../../services/authentication.service';
import {LoadingMaskOptions} from '../../../../classes/loading-mask-options';
import {ApplicationConfig} from '../../../../classes/application-config';
import {CardFilters} from '../../../../../shared/classes/card-filters';
import moment from 'moment';
import {Report} from '../../classes/report';
import {MatDialog, MatDialogConfig} from '@angular/material/dialog';
import {ReviewUserModalComponent} from '../../../user/modals/review-user-modal/review-user-modal.component';
import {ModalConfig} from '../../../../classes/modal-config';
import {ReportService} from '../../services/report.service';
import {FileDownload} from '../../../../classes/file-download';
import {AppEnvironment} from 'frontend/src/app/enums/environment.enum';
import {CreateTrainingUsersModalComponent} from '../../../user/modals/create-training-users-modal/create-training-users-modal.component';
import {filter, switchMap, take, takeUntil, tap} from 'rxjs/operators';
import {ManagementService} from '../../../management/services/management.service';
import {Observable} from 'rxjs';

class StatusFilter {
    static All: string = 'all';
    static ENABLED: string = 'enabled';
    static DISABLED: string = 'disabled';
    static NOTAPPROVED: string = 'notApproved';
    static LOGIN: string = 'login';
    static JOIN: string = 'join';
}

// tslint:disable-next-line
@Component({
    selector: 'eaglei-user-report',
    templateUrl: './user-report.component.html',
    styleUrls: ['../reports.scss', './user-report.component.scss'],
    standalone: false,
})
export class UserReportComponent extends Report<User> implements OnInit {
    // Input Properties
    @Input() isManagement: boolean = false;

    // HTML Properties
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild(MatSort) sort: MatSort;

    // Swappable Properties
    public title: string;
    public selectedFilter: StatusFilter = StatusFilter.All;
    public startDate: moment.Moment = moment().subtract(1, 'day');
    public endDate: moment.Moment = moment();

    public apiCall$: Observable<User[]>;

    // Check environment
    public readonly isTrainingEnvironment: boolean = [AppEnvironment.DEVELOPMENT, AppEnvironment.TRAINING, AppEnvironment.TEST].includes(
        (window as any)?.eaglei?.env
    );

    // Table Properties
    public readonly columnNames = [
        'name',
        'email',
        'username',
        'enabled',
        'organization',
        'joined',
        'lastLogin',
        'fed',
        'doe',
        'admin',
        'actions',
    ];
    public selectedRoles: GenRoleDefinition[] = [];
    public selectRoles: GenRoleDefinition[] = [GenRoleDefinition.ROLE_FEDERAL, GenRoleDefinition.ROLE_DOE, GenRoleDefinition.ROLE_ADMIN];

    // production test
    public readonly isProductionEnvironment: boolean = (window as any)?.eaglei?.env === AppEnvironment.PRODUCTION;

    constructor(
        private router: Router,
        private route: ActivatedRoute,
        private userService: UserService,
        private popup: MatSnackBar,
        private auth: AuthenticationService,
        private dialog: MatDialog,
        public reportService: ReportService,
        private managementService: ManagementService
    ) {
        super();
    }

    ngOnInit(): void {
        if (this.isManagement) {
            this.title = 'User Management';
            this.apiCall$ = this.userService.getMinimalUsers();
        } else {
            this.title = 'User Report';
            this.apiCall$ = this.userService.getUsers();

            this.columnNames.pop();

            this.reportService.getReportData().subscribe((r) => this.initializeReportInfo(r));
        }
        this.refreshUsers();
    }

    /**
     * Refreshes the user list
     */
    public refreshUsers(): void {
        this.showMask();
        this.apiCall$
            .pipe(
                tap(() => this.hideMask()),
                take(1)
            )
            .subscribe((users) => {
                this.initializeData(users);
            });
    }

    // DataSource Method
    /**
     * Initialized the table data source and sets all paging, filtering, and sorting, if a dataSource already exists only the data is updated.
     * @param users The users that will populate the table.
     */
    private initializeData(users: User[]): void {
        // noinspection DuplicatedCode
        if (this.dataSource) {
            this.dataSource.data = users;
        } else {
            this.dataSource = new MatTableDataSource(users);
            this.dataSource.paginator = this.paginator;
            this.dataSource.filterPredicate = this.filterPredicate.bind(this);
            this.dataSource.sortingDataAccessor = this.sortingDataAccessor;
            this.dataSource.sort = this.sort;
        }
    }

    /**
     * Filters the table to users whose name, email, username, or organization match the text passed in
     * @param user Each user to be compared
     * @param text the text that is being searched for
     */
    private filterPredicate(user: User, text: string): boolean {
        const fields = [user.fullName, user.email, user.username, user.organization];
        const textCheck = fields.some((field) => {
            return field.toLowerCase().includes(text.toLowerCase());
        });

        if (!this.isManagement) {
            let statusCheck: boolean;

            switch (this.selectedFilter) {
                case StatusFilter.ENABLED:
                    statusCheck = user.isEnabled();
                    break;
                case StatusFilter.DISABLED:
                    statusCheck = !user.enabled && user.approved;
                    break;
                case StatusFilter.NOTAPPROVED:
                    statusCheck = !user.enabled && !user.approved;
                    break;
                case StatusFilter.LOGIN:
                    statusCheck = user.lastLoginDate ? user.lastLoginDate.isBetween(this.startDate, this.endDate, 'day', '[]') : false;
                    break;
                case StatusFilter.JOIN:
                    statusCheck = user.joinDate ? user.joinDate.isBetween(this.startDate, this.endDate, 'day', '[]') : false;
                    break;
                default:
                    statusCheck = true;
            }

            return textCheck && statusCheck;
        } else {
            let roleCheck = true;
            if (this.selectedRoles.length > 0) {
                roleCheck = this.selectedRoles.some((role) => {
                    return role === undefined ? !user.enabled && !user.approved : user.hasRole(role);
                });
            }

            return textCheck && roleCheck;
        }
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Sorts the table by the given columnName
     * @param user Each user to be compared
     * @param columnName the column name that is being sorted on
     */
    private sortingDataAccessor(user: User, columnName: string): string | number {
        switch (columnName) {
            case 'name':
                return user.fullName.toLowerCase();
            case 'email':
                return user.email.toLowerCase();
            case 'username':
                return user.username.toLowerCase();
            case 'enabled':
                return user.enabled ? 1 : -1;
            case 'organization':
                return user.organization.toLowerCase();
            case 'joined':
                return user.joinedValue;
            case 'lastLogin':
                return user.lastLoginValue;
            case 'fed':
                return user.hasRole(GenRoleDefinition.ROLE_FEDERAL) ? 1 : -1;
            case 'doe':
                return user.hasRole(GenRoleDefinition.ROLE_DOE) ? 1 : -1;
            case 'admin':
                return user.isAdmin() ? 1 : -1;
        }
    }

    // Role Check Methods
    /**
     * Returns true is the user has the FEDERAL Role, false otherwise
     * @param user The user to be tested.
     */
    public isFed(user: User): boolean {
        return user.hasRole(GenRoleDefinition.ROLE_FEDERAL);
    }

    /**
     * Returns true is the user has the DOE Role, false otherwise
     * @param user The user to be tested.
     */
    public isDoe(user: User): boolean {
        return user.hasRole(GenRoleDefinition.ROLE_DOE);
    }

    // Filter Methods
    /**
     * Searches the table with the filter predicate.
     * @param searchText The text that is being searched for.
     */
    public searchUsers(searchText: string): void {
        searchText = searchText === '' ? ' ' : searchText;
        this.dataSource.filter = searchText;
    }

    /**
     * filters the table by the roles that are included in the event value.
     * @param event The event that is recieved from the view
     */
    public filterRoles(event: MatSelectChange): void {
        this.selectedRoles = event.value;

        this.searchUsers(this.dataSource.filter);
    }

    // Navigation Methods
    /**
     * Navigates to a users profile.
     * @param user The user profile to navigate to.
     */
    public gotoProfile(user: User): void {
        if (!this.isManagement) {
            console.info('In user report, can not navigate');
            return;
        }

        this.router.navigateByUrl(`app/user/profile/${user.id}`).then(() => {
            this.popup.open(`Navigated to ${user.username} profile.`, 'Okay', {panelClass: 'dialog-success', duration: 5000});
        });
    }

    // Quick Action Methods
    /**
     * Sends a user a password reset email.
     * @param user The user that will receive the email
     */
    public sendPasswordReset(user: User): void {
        this.auth.requestPasswordReset(user.username).subscribe({
            next: () => {
                this.popup.open('Password Reset Email Sent', 'Okay', {panelClass: 'dialog-success', duration: 5000});
            },
            error: (error: any) => {
                const failureText = error.userMessage || 'Failed to send password reset email';
                this.popup.open(failureText, 'Okay', {panelClass: 'dialog-failure'});
            },
        });
    }

    /**
     * Verifies the email for a user.
     * @param user The user whose email will be verified
     */
    public verifyEmail(user: User): void {
        this.userService.resendVerificationEmail(user.id).subscribe({
            next: () => {
                this.popup.open('Resent Verification Email', 'Okay', {panelClass: 'dialog-success', duration: 5000});
            },
            error: (error: any) => {
                const failureText = error.userMessage || 'Failed to send verification email';
                this.popup.open(failureText, 'Okay', {panelClass: 'dialog-failure'});
            },
        });
    }

    /**
     * Triggers the review modal for the selected user
     * @param user The user to be reviewed
     */
    public openReviewModal(user: User): void {
        const config: MatDialogConfig = {
            ...ModalConfig.defaultConfig,
            autoFocus: false,
        };

        this.userService
            .getUserById(user.id)
            .pipe(
                switchMap((fullUser) => {
                    config.data = fullUser;
                    return this.dialog.open(ReviewUserModalComponent, config).afterClosed();
                }),
                filter((enabled) => !!enabled),
                take(1)
            )
            .subscribe(() => {
                user.enabled = true;
                user.approved = true;
            });
    }

    /**
     * Impersonates the selected user if they are enabled.
     * @param user The user to be impersonated
     */
    public impersonateUser(user: User): void {
        this.auth.impersonate(user).pipe(take(1)).subscribe();
    }

    // Mask Methods
    /**
     * Configures and displays the loading mask
     */
    public showMask(): void {
        const config = new LoadingMaskOptions();
        config.showMask = true;
        ApplicationConfig.pageMask.next(config);
    }

    public hideMask(): void {
        const config = ApplicationConfig.pageMask.getValue();
        config.showMask = false;
        ApplicationConfig.pageMask.next(config);
    }

    // User Report Filters
    /**
     * selects the current filter for the table.
     * @param value the value of the new filter.
     */
    public toggleStatus(value: string): void {
        this.selectedFilter = value;
        this.searchUsers(this.dataSource.filter);
    }

    /**
     * Changes the dates being searched for last login date or join date
     * @param dates the new dates to be searched
     */
    public updateDates(dates: CardFilters): void {
        this.startDate = dates.startDate;
        this.endDate = dates.endDate;
        this.searchUsers(this.dataSource.filter);
    }

    /**
     * Creates a CSV of the table data and downloads it.
     */
    public exportTable(): void {
        const tmpUser = new User();
        let data: string = tmpUser.getUserReportColumnNames().join() + '\n';

        this.dataSource.filteredData.forEach((user) => {
            data += user.tmpGetUserReportData(FileDownload.formatCsvCell).join() + '\n';
        });

        FileDownload.downloadCSV('users', data, this.attributionUrl);
    }

    public isTextFilterActive(): boolean {
        const filterExists = this.dataSource && this.dataSource.filter;

        if (filterExists) {
            return this.dataSource.filter.length > 0 && this.dataSource.filter.trim() !== '';
        }
        return false;
    }

    /**
     * Opens the modal for creating training users
     */
    public openCreateTrainingUsersModal(): void {
        let userIds: number[] = [];
        // We can listen to the after close and handle our check logic here instead of setting up another emitter to listen for.
        this.dialog
            .open(CreateTrainingUsersModalComponent, ModalConfig.defaultConfig)
            .afterClosed()
            .pipe(
                filter((value) => !!value),
                switchMap((generatedUsers) => {
                    this.showMask();
                    userIds = generatedUsers.map((u) => u.id);
                    return this.userService.getUsers('eaglei.com');
                }),
                takeUntil(this.destroy$)
            )
            .subscribe((users) => {
                users.filter((u) => userIds.includes(u.id)).map((u) => this.dataSource.data.push(u));
                this.hideMask();
            });
    }

    /**
     * Calls the api endpoint to sync users from the production database
     */
    public syncUsersFromProduction(): void {
        if (!this.isProductionEnvironment) {
            this.showMask();
            this.managementService.syncProdUsers().subscribe(() => {
                this.apiCall$ = this.userService.getMinimalUsers();
                this.refreshUsers();
            });
        }
    }
}
