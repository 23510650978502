import {Component, ElementRef, Inject, ViewChild} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {ColumnDef} from '../../../../../shared/classes/column-def';
import {StateCoverage} from '../../classes/state-coverage';
import {BaseModal} from '../../../../classes/base-modal';

@Component({
    selector: 'eaglei-state-coverage-modal',
    templateUrl: './state-coverage-modal.component.html',
    styleUrls: ['../../../../../shared/modals/shared-modals.scss', './state-coverage-modal.component.scss'],
    standalone: false,
})
export class StateCoverageModalComponent extends BaseModal {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('content') content: ElementRef;

    public readonly columns: string[] = [];
    public columnDefs: ColumnDef[];
    public dataSource: MatTableDataSource<StateCoverage>;
    public defaultSort = '';
    public title = 'TMP';
    private readonly columnData: StateCoverage[];
    public defaultSortDirection: 'asc' | 'desc';

    constructor(public ref: MatDialogRef<StateCoverageModalComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
        super();
        this.columnDefs = data.columnDefs;
        this.columns = this.columnDefs.map((cd) => cd.columnName);

        const defaultSortedColumn = this.columnDefs.find((cd) => cd.defaultSort);

        this.defaultSort = defaultSortedColumn ? defaultSortedColumn.columnName : undefined;
        this.defaultSortDirection = defaultSortedColumn ? defaultSortedColumn.sortDirection : 'desc';
        this.title = data.title;
        this.columnData = data.coverageData;
    }

    afterInit() {
        setTimeout(() => {
            this.initializeData(this.columnData);
        }, 0);
    }

    /**
     * Sets up the data for the table, sorting, and paginator
     * @param data The data to be displayed in the table.
     */
    private initializeData(data: StateCoverage[]) {
        this.dataSource = new MatTableDataSource(data);
        this.dataSource.sortingDataAccessor = this.sortAccessor.bind(this);
        this.dataSource.sort = this.sort;
        this.dataSource.paginator = this.paginator;
    }

    /**
     * Sets up how sorting on the table is handled
     * @param data The data being sorted
     * @param header The column that is being sorted on.
     */
    private sortAccessor(data: any, header: string): string | number {
        const column = this.columnDefs.find((cd) => cd.columnName === header);
        return !column ? '' : column.sortValue(data);
    }
}
