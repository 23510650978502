import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {Report} from '../../classes/report';
import moment from 'moment';
import {ReportService} from '../../services/report.service';
import {RecentEvent} from '../../../recent-events/classes/recent-event';
import {CardFilters} from '../../../../../shared/classes/card-filters';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {OeData} from '../../classes/oe-data';
import {RecentEventSourceType} from '../../../recent-events/enums/recent-event-source-type';
import {MatSelectChange} from '@angular/material/select';
import {FileDownload} from '../../../../classes/file-download';

@Component({
    selector: 'eaglei-oe-report',
    templateUrl: './oe-report.component.html',
    styleUrls: ['../reports.scss', './oe-report.component.scss'],
    standalone: false,
})
export class OeReportComponent extends Report<OeData> implements OnInit, AfterViewInit {
    // HTML Properties
    @ViewChild('chartTarget', {static: true}) chartTarget: ElementRef;
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    // Filter Properties
    public startDate: moment.Moment = moment().subtract(1, 'days').startOf('day');
    public endDate: moment.Moment = moment();
    private activeEvent: RecentEvent;

    public types: string[] = [];
    public selectedTypes: string[] = [];

    // Table Properties
    public readonly columns: string[] = ['title', 'organization', 'type', 'reportDate'];

    // Data Properties
    private initialLoad = true;

    constructor(public reportService: ReportService) {
        super();
    }

    ngOnInit() {
        this.reportService.getReportData().subscribe((res) => this.initializeReportInfo(res));
    }

    ngAfterViewInit(): void {
        this.getOeData();
    }

    // Data Methods
    private getOeData(): void {
        this.reportService.getRecentEventData(RecentEventSourceType.OE417, this.startDate, this.endDate).subscribe((res) => {
            const typeSet = new Set<string>();
            const organizationSet = new Set<string>();

            const events = res.data.map((event: RecentEvent) => {
                const data = new OeData(event);
                typeSet.add(data.type);
                organizationSet.add(data.organization);
                return data;
            });

            this.types = Array.from(typeSet.values());
            this.selectedTypes = this.types.slice();

            this.initialLoad = false;

            this.initializeData(events);
        });
    }

    // Table methods
    /**
     * Sets the data used in the table and also creates all the interactions on the table
     * @param data A list of recent events to be in the table.
     */
    private initializeData(data: OeData[]): void {
        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource(data);
            this.dataSource.filterPredicate = this.filterPredicate.bind(this);
            this.dataSource.paginator = this.paginator;
            this.dataSource.sortingDataAccessor = this.dataAccessor.bind(this);
            this.dataSource.sort = this.sort;
        }

        if (data.length !== 0) {
            // this.updateChartData();
        }
    }

    /**
     * Sets up the logic behind how the table is filtered, is called when the filter property on the dataSource is changed.
     * @param data The recent event being tested.
     * @param text The text being searched for.
     */
    private filterPredicate(data: OeData, text: string): boolean {
        const searchFields = [data.title, data.organization].join().toLowerCase();
        const term = data.type;
        const textCheck = searchFields.includes(text.trim().toLowerCase());
        const typeCheck = this.selectedTypes.indexOf(term) !== -1;
        return textCheck && typeCheck;
    }

    // noinspection JSMethodCanBeStatic
    /**
     * The logic behind how the columns are sorted.
     * @param data The recent event being sorted
     * @param header The column name being sorted on.
     */
    private dataAccessor(data: OeData, header: string): string | number {
        switch (header) {
            case 'title':
                return data.title.toLowerCase();
            case 'organization':
                return data.organization.toLowerCase();
            case 'type':
                return data.type.toLowerCase();
            case 'reportDate':
                return data.newsDate.valueOf();
            default:
                return '';
        }
    }

    // Filter Methods
    /**
     * Called when the date range is updated, changed the dates for the API and fetches new data.
     * @param dates The new start and end date.
     */
    public updateDateRange(dates: CardFilters) {
        this.startDate = dates.startDate;
        this.endDate = dates.endDate;

        this.getOeData();
    }

    /**
     * Called when the searched bar is changed. Updates the filter property on the dataSource
     * @param text The text being searched for.
     */
    public filterData(text: string): void {
        text = text === '' ? ' ' : text;
        this.dataSource.filter = text;
    }

    /**
     * Checks to see if there is text in the search field
     */
    public isSearchTextActive(): boolean {
        return this.dataSource && this.dataSource.filter && this.dataSource.filter.trim().length > 0;
    }

    // Export Methods
    /**
     * Exports the table as a CSV File
     */
    public exportTable() {
        let data: string = ['Title', 'Organization', 'Type', 'Report Date'].join() + '\n';

        this.dataSource._orderData(this.dataSource.filteredData).forEach((val) => {
            data += [
                FileDownload.formatCsvCell(val.title),
                FileDownload.formatCsvCell(val.organization),
                FileDownload.formatCsvCell(val.type),
                FileDownload.formatCsvCell(Report.momentPipe.transform(val.newsDate)),
            ].join();
            data += '\n';
        });

        FileDownload.downloadCSV('OE417Report', data, this.attributionUrl);
    }

    // Helper Methods
    /**
     * Checks to see if a row is expanded
     * @param info the row that is bring checked for expansion.
     */
    public isActiveEvent(info: RecentEvent): boolean {
        return this.activeEvent && this.activeEvent.id === info.id;
    }

    /**
     * Changes the expanded row in the table, if the row is already expanded, it will close it
     * @param row The row to be expanded
     */
    public toggleActiveReport(row: RecentEvent): void {
        this.activeEvent = this.isActiveEvent(row) ? undefined : row;
    }

    /**
     * Event that is called when the selected types change
     * @param event the event that is fired with the new list of selected types
     */
    public filterTypes(event: MatSelectChange): void {
        this.selectedTypes = event.value;
        this.filterData(this.dataSource.filter);
    }
}
