import {AfterViewInit, Component, ElementRef, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {Report} from '../../classes/report';
import {Coverage} from '../../classes/coverage';
import moment from 'moment';
import {ReportService} from '../../services/report.service';
import {FileDownload} from '../../../../classes/file-download';
import {CardFilters} from '../../../../../shared/classes/card-filters';
import {PopoverElement} from '../../../../classes/popover-element';
import {ApplicationConfig} from '../../../../classes/application-config';
import {EagleiBaseChart} from '../../../../classes/charts/base-chart';

@Component({
    selector: 'eaglei-coverage-report',
    templateUrl: './coverage-report.component.html',
    styleUrls: ['../reports.scss', './coverage-report.component.scss'],
    standalone: false,
})
export class CoverageReportComponent extends Report<Coverage> implements OnInit, AfterViewInit {
    // HTML Elements
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;
    @ViewChild('chartTarget') chartTarget: ElementRef<HTMLDivElement>;

    // Table properties
    public columns = ['date', 'coveredCustomers', 'totalCustomers', 'coveragePercent', 'dailyTrend'];

    // Filter properties
    public startDate = moment().subtract(2, 'months');
    public endDate = moment();
    public readonly minDate = moment().subtract(2, 'months');

    // Chart Properties
    public baseChart = new EagleiBaseChart();

    // Mask Properties
    public mask = {
        show: true,
        isLoading: false,
        progressBar: false,
        dateRangeError: false,
    };

    constructor(public reportService: ReportService) {
        super();
    }

    ngOnInit() {
        this.reportService.getReportData().subscribe((r) => this.initializeReportInfo(r));
    }

    ngAfterViewInit() {
        this.getCoverage();
        this.initializeChart();
    }

    // API Methods
    private getCoverage(): void {
        this.mask.isLoading = true;
        this.mask.show = true;

        this.reportService.getCoverage(this.startDate, this.endDate).subscribe((res) => {
            this.paginator.firstPage();
            this.mask.isLoading = false;
            this.initializeData(res);
        });
    }

    // Table Methods
    private initializeData(data: Coverage[]) {
        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource<Coverage>(data);
            this.dataSource.sortingDataAccessor = this.dataAccessor;
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.dataSource.filterPredicate = this.filterPredicate.bind(this);
        }
        this.createChart(this.dataSource.data);
    }

    private filterPredicate(data: Coverage): boolean {
        const start = moment(this.startDate).startOf('day');
        const end = moment(this.endDate).endOf('day');

        return data.coverageDate.isBetween(start, end, undefined, '[]');
    }

    // noinspection JSMethodCanBeStatic
    private dataAccessor(data: Coverage, columnName: string): string | number {
        switch (columnName) {
            case 'date':
                return data.coverage_Date;
            case 'coveredCustomers':
                return data.coveredCustomers;
            case 'totalCustomers':
                return data.totalCustomers;
            case 'coveragePercent':
                return data.percent;
            case 'dailyTrend':
                return data.trendDirection;
            default:
                return '';
        }
    }

    // Chart Methods
    private initializeChart() {
        this.baseChart.initializeEChart(this.chartTarget.nativeElement, true, 'Date', 'Customers Covered');
    }

    private createChart(data: Coverage[]): void {
        const xValues = [];
        const yValues = data
            .sort((a, b) => (moment(a.coverageDate).isSameOrAfter(b.coverageDate) ? 1 : -1))
            .map((d) => {
                xValues.push(d.coverageDate.valueOf());

                return {
                    value: d.coveredCustomers,
                    itemStyle: {color: ApplicationConfig.chartLineColor},
                    popoverData: [
                        new PopoverElement().setTitle().setValue(Report.momentPipe.transform(d.coverageDate, 'MM/DD/YYYY')),
                        new PopoverElement()
                            .setLabel('Covered Customers')
                            .setValue(Report.numberPipe.transform(d.coveredCustomers).toString())
                            .setSubValue('(Collected)')
                            .setSubValueClass('county-customer'),
                        new PopoverElement('Total Customers', `${Report.numberPipe.transform(d.totalCustomers)}`)
                            .setSubValue('(Collected)')
                            .setSubValueClass('county-customer'),
                        new PopoverElement('Coverage Percent', `${Report.percentPipe.transform(d.percent, '1.2-2')}`),
                    ],
                    exportData: {
                        yValue: d.coveredCustomers,
                        xValue: d.coverageDate.utc().format(),
                        percent: Report.percentPipe.transform(d.percent, '1.2-2'),
                        total: d.coveredCustomers,
                    },
                };
            });

        this.baseChart.eChartOptions.xAxis['data'] = xValues;

        this.baseChart.eChartOptions.series = [
            {
                type: 'bar',
                data: yValues,
            },
        ];

        this.baseChart.eChart.setOption(this.baseChart.eChartOptions);

        this.mask.show = yValues.length === 0;
    }

    public getTrendColor(coverage: Coverage): string {
        const trend = coverage.trendDirection;
        if (trend !== undefined) {
            if (trend.toLowerCase() === 'down') {
                return '#ff0000';
            } else if (trend.toLowerCase() === 'up') {
                return '#006400';
            }
        }
        return '#000000';
    }

    // Filter Methods
    public changeDateRange(dates: CardFilters) {
        this.mask.show = true;

        this.startDate = dates.startDate;
        this.endDate = dates.endDate;

        this.dataSource.filter = ' ';

        const start = moment(this.startDate).startOf('day');
        const end = moment(this.endDate).endOf('day');

        if (start.isSame(end, 'day') && this.dataSource.filteredData.length === 0) {
            this.mask.dateRangeError = true;
            return;
        }
        this.mask.dateRangeError = false;
        this.paginator.pageIndex = 0;

        this.createChart(this.dataSource.filteredData);
    }

    // Mask methods
    public getMaskText(): string {
        let text = 'No Data Available';
        this.mask.progressBar = false;
        if (this.mask.dateRangeError) {
            text = 'Date range must be one day or longer';
        } else if (!this.baseChart.hasData()) {
            text = 'No Data Available';
        } else if (this.mask.isLoading) {
            text = 'Loading...';
            this.mask.progressBar = true;
        }
        return text;
    }

    public getCountyCustomerText(info: any): string {
        return !!info.coveredCustomers ? `(${info.modelCount > 0 ? 'Modeled' : 'Collected'})` : '';
    }

    // Export Methods
    public exportTable(): void {
        let data = '';

        data = this.columns
            .map((val) => {
                val = val.replace(/[A-Z]/g, ' $&');
                return val[0].toUpperCase() + val.slice(1);
            })
            .join();
        data += '\n';

        this.dataSource._orderData(this.dataSource.filteredData).forEach((val) => {
            data += [
                val.coverageDate.utc().format(),
                val.coveredCustomers,
                val.totalCustomers,
                Report.percentPipe.transform(val.percent, '1.2-2'),
                val.trendDirection,
            ].join();
            data += '\n';
        });

        FileDownload.downloadCSV('coverageReport', data, this.attributionUrl);
    }

    /**
     * Exports the chart as CSV file
     */
    public exportChartAsCsv() {
        let data = '';

        const columns = ['Date', 'Covered Customers', 'Total Customers', 'Coverage Percent'].join() + '\n';

        data += columns;

        this.baseChart.eChart.getOption().series.forEach((series) => {
            series.data.forEach((sd) => {
                data +=
                    [
                        FileDownload.formatCsvCell(sd.exportData.xValue),
                        FileDownload.formatCsvCell(sd.exportData.yValue),
                        FileDownload.formatCsvCell(sd.exportData.total),
                        FileDownload.formatCsvCell(sd.exportData.percent),
                    ].join() + '\n';
            });
        });

        FileDownload.downloadCSV('CoverageTrend', data, this.attributionUrl);
    }

    public exportChart(): void {
        const title =
            `EAGLE-I Coverage for ` +
            `${Report.momentPipe.transform(this.startDate, 'M/DD/YYYY')} to ` +
            `${Report.momentPipe.transform(this.endDate, 'M/DD/YYYY')}`;
        FileDownload.exportChartAsPNG(this.baseChart, 'coverage', title, this.attributionUrl);
    }
}
