import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {HttpInterceptorService} from 'frontend/src/app/services/http-interceptor.service';
import moment from 'moment';
import {CardFilters} from '../../../../../shared/classes/card-filters';
import {Report} from '../../classes/report';
import {UtilityError} from '../../classes/utility-error';
import {ReportService} from '../../services/report.service';
import {ApplicationConfig} from '../../../../classes/application-config';
import {FileDownload} from '../../../../classes/file-download';
import {FormControl} from '@angular/forms';
import {takeUntilDestroyed} from '@angular/core/rxjs-interop';
import {filter} from 'rxjs/operators';

@Component({
    selector: 'eaglei-utility-repeated-outage-report',
    templateUrl: './utility-repeated-outage-report.component.html',
    styleUrls: ['../reports.scss', './utility-repeated-outage-report.component.scss'],
    standalone: false,
})
export class UtilityRepeatedOutageReportComponent extends Report<UtilityError> implements OnInit, AfterViewInit {
    // HTML Properties
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    // Filters Properties
    public startDate: moment.Moment = moment().subtract(1, 'days').startOf('day');
    public endDate: moment.Moment = moment();
    public dateRangeControl = new FormControl<'inclusive' | 'match'>('inclusive', {nonNullable: true});

    private readonly threshold = 1;

    // Table Properties
    public readonly columnNames: string[] = [
        'eagle_id',
        'sub_utility_id',
        'utility_name',
        'county_name',
        'state',
        'customers_out',
        'distinct',
        'percent_repeated',
        'start',
        'end',
    ];

    constructor(public reportService: ReportService) {
        super();

        this.dateRangeControl.valueChanges
            .pipe(
                filter(() => !!this.dataSource),
                takeUntilDestroyed()
            )
            .subscribe(() => this.filterData(this.dataSource.filter));
    }

    ngOnInit() {
        this.reportService.getReportData().subscribe((r) => this.initializeReportInfo(r));
    }

    public ngAfterViewInit(): void {
        this.getRepeatedUtilityErrors();
    }

    /**
     * API call to get repeated utility outage counts
     */
    private getRepeatedUtilityErrors(): void {
        if (HttpInterceptorService.pendingRequests['utilityError']) {
            HttpInterceptorService.pendingRequests['utilityError'].unsubscribe();
        }

        HttpInterceptorService.pendingRequests['utilityError'] = this.reportService
            .getUtilityFeed(this.startDate, this.endDate, this.threshold)
            .subscribe((res) => {
                delete HttpInterceptorService.pendingRequests['utilityError'];
                this.initializeData(res);
            });
    }

    /**
     * Creates connection for tables datasource to the data set
     * @param data Utility Error data to set
     */
    private initializeData(data: UtilityError[]): void {
        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource<UtilityError>(data);
            this.dataSource.sortingDataAccessor = this.dataAccessor;
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
            this.dataSource.filterPredicate = this.filterPredicate.bind(this);
        }

        this.filterData(this.dataSource.filter);
    }

    // noinspection JSMethodCanBeStatic
    /**
     * Checks utility data to match to filters
     * @param data Utility to check
     * @param textFilter search string
     */
    private filterPredicate(data: UtilityError, textFilter: string): boolean {
        const nameCheck = textFilter === ' ' ? true : data.utilityName.toLowerCase().includes(textFilter.toLowerCase());
        let dateCheck = true;
        if (this.dateRangeControl.value === 'match') {
            dateCheck = this.startDate.isSame(data.duplicateStart, 'day') && this.endDate.isSame(data.duplicateEnd, 'day');
        }

        return nameCheck && dateCheck;
    }

    // noinspection JSMethodCanBeStatic

    /**
     * Sorts the utility data based on column
     * @param data Utility to check
     * @param column column to be sorted
     */
    private dataAccessor(data: UtilityError, column: string): string | number {
        switch (column) {
            case 'eagle_id':
                return data.eagleiId;
            case 'sub_utility_id':
                return data.subUtilityId;
            case 'utility_name':
                return data.utilityName.toLowerCase();
            case 'state':
                return data.state;
            case 'county_name':
                return data.countyName.toLowerCase();
            case 'customers_out':
                return data.customersOut;
            case 'distinct':
                return data.duplicateCount;
            case 'percent_repeated':
                return data.percentRepeated;
            case 'start':
                return data.duplicateStart.valueOf();
            case 'end':
                return data.duplicateEnd.valueOf();
            default:
                return '';
        }
    }

    /**
     * Filters to the table
     * @param value search string
     */
    public filterData(value: string): void {
        this.dataSource.filter = value === '' ? ' ' : value;
    }

    /**
     * Updates the start and end dates and calls the API
     * @param dates Dates to set
     */
    public updateDates(dates: CardFilters): void {
        this.startDate = dates.startDate;
        this.endDate = dates.endDate;
        this.getRepeatedUtilityErrors();
    }

    /**
     * Exports table as CSV
     */
    public exportTable(): void {
        const columns = [
            'Eagle-I ID',
            'Sub-Utility ID',
            'Utility Name',
            'County',
            'State/Territory',
            'Customers Out',
            'Distinct Count',
            '% of Outage Reports',
            'First Occurance',
            'Last Occurance',
        ];
        let data = `${Report.momentPipe.transform(this.startDate)} - ${Report.momentPipe.transform(this.endDate)}\n\n`;
        data += columns.join() + '\n';

        this.dataSource._orderData(this.dataSource.filteredData).forEach((utility) => {
            data += [
                FileDownload.formatCsvCell(utility.eagleiId),
                FileDownload.formatCsvCell(utility.subUtilityId),
                FileDownload.formatCsvCell(utility.utilityName),
                FileDownload.formatCsvCell(utility.countyName),
                FileDownload.formatCsvCell(utility.state),
                FileDownload.formatCsvCell(utility.customersOut),
                FileDownload.formatCsvCell(utility.duplicateCount),
                FileDownload.formatCsvCell(Report.percentPipe.transform(utility.percentRepeated, '1.2-2')),
                FileDownload.formatCsvCell(Report.momentPipe.transform(utility.duplicateStart)),
                FileDownload.formatCsvCell(Report.momentPipe.transform(utility.duplicateEnd)),
            ].join();
            data += '\n';
        });
        FileDownload.downloadCSV('utilityRepeatOutages', data, this.attributionUrl);
    }

    public hasEllipsis(element: HTMLElement): boolean {
        return ApplicationConfig.hasEllipsis(element);
    }
}
