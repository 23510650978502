import {Component, OnInit, ViewChild} from '@angular/core';
import {FormControl, FormGroup} from '@angular/forms';
import {MatPaginator} from '@angular/material/paginator';
import {MatSort} from '@angular/material/sort';
import {MatTableDataSource} from '@angular/material/table';
import {FileDownload} from 'frontend/src/app/classes/file-download';
import {HttpInterceptorService} from 'frontend/src/app/services/http-interceptor.service';
import {map, tap} from 'rxjs/operators';
import {LeafletMapLayer} from '../../../layer/classes/leaflet-map-layer';
import {LayerService} from '../../../layer/services/layer.service';
import {PoleDetectionSource} from '../../../layer/sources/pole-detection-source';
import {MapOptions} from '../../../map/classes/map-options';
import {Report} from '../../classes/report';
import {ReportService} from '../../services/report.service';
import {GenPole} from 'frontend/generated/serverModels/GenPole';

@Component({
    selector: 'eaglei-pole-detection-report',
    templateUrl: './pole-detection-report.component.html',
    styleUrls: ['../reports.scss', './pole-detection-report.component.scss'],
    standalone: false,
})
export class PoleDetectionReportComponent extends Report<GenPole> implements OnInit {
    @ViewChild(MatSort) sort: MatSort;
    @ViewChild(MatPaginator) paginator: MatPaginator;

    // Table Properties
    public readonly columnNames: string[] = [
        'flight_date',
        'infrastructure',
        'poleId',
        'callSign',
        'learning_model',
        'type',
        'damaged',
        'confidence',
        'detections',
    ];

    // Map Properties
    public mapOptions: MapOptions;
    private mapLayer: LeafletMapLayer;
    public layerSource: PoleDetectionSource;

    // Filter Properties
    public filterControlGroup: FormGroup = new FormGroup({
        infrastructure: new FormControl('all'),
        status: new FormControl('all'),
    });

    constructor(private layerService: LayerService, public reportService: ReportService) {
        super();

        this.mapOptions = new MapOptions().setZoom(3, 1).setCenter(37.2, -92);
        this.mapOptions.onlyManualZoom = true;
        this.mapOptions.show = {
            sidebar: false,
            refresh: false,
            export: false,
            coverage: false,
            coordinate: false,
            zoom: true,
            panControl: true,
        };
    }

    public ngOnInit(): void {
        this.reportService.getReportData().subscribe((r) => this.initializeReportInfo(r));
        this.layerService.getLayerByHandle('poleDetection').subscribe((layer) => {
            this.mapLayer = layer;
            this.layerSource = new PoleDetectionSource(layer);

            this.getData();
        });
    }

    /**
     * Get the Pole Detection Data to display on the map
     */
    private getData(): void {
        const url = this.mapLayer?.serviceurl;
        this.mapLayer.startLoading();

        HttpInterceptorService.clearInterceptor(this.interceptorKey);
        HttpInterceptorService.pendingRequests[this.interceptorKey] = this.layerService
            .getJsonFromUrl(url)
            .pipe(
                tap(() => {
                    HttpInterceptorService.deleteFromInterceptor(this.interceptorKey);
                    this.mapLayer.endLoading();
                }),
                map((poles: any[]) => poles.map((pole) => new GenPole(pole)))
            )
            .subscribe((f) => {
                this.layerSource.processFeatures(f);
                this.layerSource.addToMap();

                this.initData(f);
            });
    }

    /**
     * Creates datasource for table and adds data to table
     * @param data Data to display in table
     */
    private initData(data: GenPole[]): void {
        if (this.dataSource) {
            this.dataSource.data = data;
        } else {
            this.dataSource = new MatTableDataSource<GenPole>(data);
            this.dataSource.sortingDataAccessor = this.dataAccessor.bind(this);
            this.dataSource.filterPredicate = this.filterPredicate.bind(this);
            this.dataSource.sort = this.sort;
            this.dataSource.paginator = this.paginator;
        }
    }

    /**
     * Gets the data to be ordered for the sort headers
     * @param data Data of a row
     * @param column Column Header to order
     * @returns Data of row to be ordered
     */
    private dataAccessor(data: GenPole, column: string): string | number {
        switch (column) {
            case 'infrastructure':
                return data.infrastructure;
            case 'callSign':
                return data.callSign;
            case 'type':
                return data.type;
            case 'confidence':
                return data.confidence;
            case 'flight_date':
                return data.flightDate.valueOf();
            case 'detections':
                return data.detections;
            case 'learning_model':
                return data.learningModel;
            default:
                return '';
        }
    }

    /**
     * Checks the data to match to the filters
     * @param data Data to be checked
     * @returns Whether the data matches the filter checks
     */
    private filterPredicate(data: GenPole): boolean {
        const infra = this.filterControlGroup.get('infrastructure');
        const status = this.filterControlGroup.get('status');
        const infraCheck =
            infra.value === 'pole'
                ? data.infrastructure === 'UTILITY_POLE'
                : infra.value === 'substation'
                ? data.infrastructure === 'SUBSTATION'
                : true;
        const damageCheck =
            status.value === 'damaged' ? data.damaged === true : status.value === 'undamaged' ? data.damaged === false : true;
        return infraCheck && damageCheck;
    }

    /**
     * Updates the filter to filter the table
     */
    public updateFilter(): void {
        this.dataSource.filter = ' ';

        this.layerSource.processFeatures(this.dataSource.filteredData);
        this.layerSource.addToMap(true);
    }

    /**
     * Zoom to selected row
     * @param data Data to zoom to
     */
    public zoomToFeature(data: GenPole): void {
        const layer = this.layerSource.source.getLayers().find((l: any) => l?.feature.properties.id === data.id);

        if (!!layer) {
            this.layerSource.fitToFeatures([layer]);
        }
    }

    /**
     * Exports the data from the table as a CSV
     */
    public exportTable(): void {
        let data = '';
        const headerColumns = [
            'Flight Date',
            'Infrastructure',
            'Pole ID',
            'Call Sign',
            'Learning Model',
            'Type',
            'Status',
            'Confidence',
            'Detections',
        ];

        data += headerColumns.join();
        data += '\n';

        this.dataSource._orderData(this.dataSource.filteredData).forEach((d) => {
            const values = [
                FileDownload.formatCsvCell(Report.momentPipe.transform(d.flightDate.format())),
                FileDownload.formatCsvCell(d.infrastructure),
                FileDownload.formatCsvCell(d.poleId),
                FileDownload.formatCsvCell(d.callSign),
                FileDownload.formatCsvCell(d.learningModel),
                FileDownload.formatCsvCell(d.type),
                FileDownload.formatCsvCell(d.damaged ? 'Damaged' : 'Undamaged'),
                FileDownload.formatCsvCell(Report.percentPipe.transform(d.confidence, '0.0-2')),
                FileDownload.formatCsvCell(d.detections),
            ];

            data += values.join();
            data += '\n';
        });

        FileDownload.downloadCSV('pole-detection', data, this.mapLayer.attributionUrl);
    }
}
