import {Component, inject, Inject} from '@angular/core';
import {MAT_DIALOG_DATA, MatDialogRef} from '@angular/material/dialog';
import {User} from '../../classes/user';
import {debounceTime} from 'rxjs/operators';
import {GenRoleDefinition} from '../../../../../../generated/serverModels/GenRoleDefinition';
import {Router} from '@angular/router';
import {UserService} from '../../services/user.service';
import {MatSnackBar} from '@angular/material/snack-bar';
import {BaseModal} from '../../../../classes/base-modal';

@Component({
    selector: 'eaglei-review-user-modal',
    templateUrl: './review-user-modal.component.html',
    styleUrls: ['./review-user-modal.component.scss'],
    standalone: false,
})
export class ReviewUserModalComponent extends BaseModal {
    public dialogRef = inject(MatDialogRef<ReviewUserModalComponent>);
    private router = inject(Router);
    private userService = inject(UserService);
    private popup = inject(MatSnackBar);

    public readonly roles = GenRoleDefinition.values()
        .filter((role) => {
            return ![GenRoleDefinition.ROLE_APPROVED_USER, GenRoleDefinition.ROLE_RESETTING_PASSWORD].includes(role);
        })
        .sort((a, b) => (a.name > b.name ? 1 : -1));

    constructor(@Inject(MAT_DIALOG_DATA) public user: User) {
        super();
    }

    // Abstract from parent class
    afterInit() {}

    /**
     * The event that is called when a role checkbox is changed
     */
    // We could move this to a form control, but we are not sure if we are keeping this component past 2023, so we will re-visit that if we decide to keep it
    public updateSelectedRoles(selected: boolean, role: GenRoleDefinition): void {
        if (selected) {
            this.user.roles.push(role);
        } else {
            const index = this.user.roles.findIndex((r) => r === role);
            this.user.roles.splice(index, 1);
        }
        this.updateUserRoles();
    }

    /**
     * Updates the roles for the user after a timeout.
     */
    private updateUserRoles(): void {
        this.userService
            .updateUser(this.user)
            .pipe(debounceTime(500))
            .subscribe({
                next: () => {
                    this.popup.open('Added Role', 'Okay', {duration: 5000, panelClass: 'dialog-success'});
                },
                error: () => {
                    this.popup.open('Failed to add role', 'Okay', {duration: 5000, panelClass: 'dialog-failure'});
                },
            });
    }

    /**
     * Enabled the selected user.
     */
    public enableUser(): void {
        this.userService.enableUser(this.user).subscribe({
            next: (user) => {
                this.user.accountEnabled = user.isEnabled();
                this.popup.open(`${this.user.fullName} has been enabled.`, 'Okay', {
                    duration: 5000,
                    panelClass: 'dialog-success',
                });
            },
            error: () => {
                this.popup.open(`Failed to enable ${this.user.fullName}`, 'Okay', {
                    duration: 5000,
                    panelClass: 'dialog-failure',
                });
            },
        });
    }

    /**
     * Navigates to the users profile.
     */
    public gotoProfile(): void {
        this.router.navigateByUrl(`app/user/profile/${this.user.id}`).then(() => {
            this.dialogRef.close();
        });
    }

    closeModal() {
        // Passing this to the close method because if we enabled the user we need to reflect that in the table
        this.dialogRef.close(this.user.accountEnabled);
    }
}
