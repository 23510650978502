import {Component, ElementRef, ViewChild} from '@angular/core';
import {MatDialogRef} from '@angular/material/dialog';
import {BaseModal} from '../../../../classes/base-modal';

@Component({
    selector: 'eaglei-restoration-info-modal',
    templateUrl: './restoration-info-modal.component.html',
    styleUrls: ['../../../../../shared/modals/shared-modals.scss', './restoration-info-modal.component.scss'],
    standalone: false,
})
export class RestorationInfoModalComponent extends BaseModal {
    @ViewChild('content', {static: true}) content: ElementRef;

    constructor(public dialogRef: MatDialogRef<RestorationInfoModalComponent>) {
        super();
    }

    afterInit() {}
}
